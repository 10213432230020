<!-- Mto Grupos funerarios -->

<template>
  <div class="apdsLiqExtrasM" v-if="loaded">    
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>  

       <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4"> 
              <div class="contenedor" style="width:600px">     
                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div v-if="!readOnly" class="pt-2 d-flex justify-left">

                  <!-- Mto -->            
                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>

                  <!-- Extra -->
                  <baseBtraExtra
                    style="margin-left:25px"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"           
                    @onEvent="execAccion">             
                  </baseBtraExtra>  

                </div>      

                <!-- Controles del Mto -->
                <div class="columna" style="padding-top:20px">

                    <div style="display:flex">  

                        <uiDate
                            style="width:30%"
                            styleLabel="width:100px"
                            styleInput="font-size: 14px;"
                            type="datetime-local"
                            v-model="ct.fhhr[2]"
                            :label="ct.fhhr[1]"                                              
                            readonly>                   
                        </uiDate>

                        <uiText
                            style="width:30%"
                            styleLabel="width:100px"
                            v-model="ct.liq[2]"
                            :label="ct.liq[1]"                            
                            readonly>               
                        </uiText>
                        <uiText
                            style="width:30%"
                            styleLabel="width:100px"
                            v-model="ct.liq_fh[2]"
                            :label="ct.liq_fh[1]"                            
                            readonly>               
                        </uiText>

                    </div>

                    <div style="display:flex">  
                        <ctrlF 
                            style="width:80%"
                            :ct="ct.apd_id"
                            :fn="extraeApd"
                            tipoView="FCA"
                            mView="apdM"
                            :ctView=" {                            
                                codigo:['codigo', 'Código'],
                                name:['name', 'Nombre'],
                                emp:['emp', 'Empresa'],                            
                                tlf1:['tlf1', 'Teléfono'],
                                mov1:['mov1', 'Móvil'],
                                fax1:['fax1', 'Fax'],
                                email1:['email1', 'Email'],                            
                            }"                            
                            :masterStore="masterStore"
                            :edicion="!noEdit"
                            @clear="clearApd">
                        </ctrlF>
                        <uiText
                            style="width:20%"
                            styleLabel="width:100px"
                            v-model="ct.apd_cod[2]"
                            :label="ct.apd_cod[1]"                            
                            readonly>               
                        </uiText>
                    </div>

                    <div style="display:flex">  
                        <ctrlF 
                            style="width:30%"
                            :ct="ct.exp_id"
                            :fn="extraeExp"
                            tipoView="FCA"
                            mView="expM"                            
                            :ctView=" {                            
                                control:['control', 'Expediente'],
                                fallename:['fallename', 'Fallecido'],
                                                           
                            }"                            
                            :masterStore="masterStore"
                            :edicion="!noEdit"
                            @clear="clearApd">
                        </ctrlF> 
                        <uiText
                            style="width:70%"
                            styleLabel="width:100px"
                            v-model="ct.fallename[2]"
                            :label="ct.fallename[1]"                            
                            readonly>               
                        </uiText>
                    </div>


                    <div style="display:flex; margin-top:15px;margin-bottom:20px">          
                        <uiText
                            style="font-weight: semibold; width:75%;"       
                            v-model=" ct.des[2]"
                            :label=" ct.des[1]"
                            :disabled="noEdit">
                        </uiText>
                        <uiText
                            style="font-weight: bold; width:25%;"       
                            v-model=" ct.imp[2]"
                            :label=" ct.imp[1]"
                            format="money"
                            :ndecimals=2
                            :disabled="noEdit">
                        </uiText>
                    </div>

                    

                  <!-- <ctrlF               
                    :ct="exp"
                    :edicion="!noEdit">                        
                  </ctrlF> -->
                </div>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Extra  -->      
            <component      
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"
              :readOnly="readOnly"
              :viewMtoOnly="viewMtoOnly"
              tipoDocumento='6'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>
          </div>
       </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js"; 
  import baseHeader from "@/base/baseHeader";
  import dualTemplate from "@/components/dualTemplate";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra"; 
  import commonDocsF from "@/components/commonDocsF";  
     
  export default {
    mixins: [mixM],  
    components: { baseHeader, dualTemplate, baseBtraMto, baseBtraExtra, commonDocsF },
    props: {},

    data() {
      return {
        stIni: {
          api: "apdsLiqExtrasM",
          name:"apdsLiqExtrasM",          
          titulo:"APDS Liquidaciones Extras",
          recordAccess:"local",
          mView:'',
          pView:[]        
        },  

        exp:['loc', 'Origen',  '', {},
            {comp: {
                type:"pickV1",
                api:"generic",						
                source:"exp",
                fAux:"name",					
                valueAux:"",                            
                finder:"expF",
                tipoView:"FC",
                mView:"", 
                ctView:""
            }}
        ],
      };
    },  
    

    methods: {
        iniDataParticular() {
            console.log("DEV ********************** (iniDataParticular)"); 

            // añado botones extra
            this.btExtCfg.btnsAccion= [
            //{ accion:'docs', btn: "docs" }        
            ];
        },

        
        // gancho final para todas las operaciones de CRUD
        crudFin() {
            this.botonerasSet();
        },


        // COMPONENTES DINAMICOS (asignación)
        //
        docs() {
            this.componenteDinamico= 'commonDocsF';
        },


        //
        botonerasSet() {
            this.btnSet(this.btExtCfg, 'docs', { view: true, disabled:!this.noEdit? true : false });        
        },

        // se ejecuta al extraer el Expediente
        extraeExp(r) {
            this.ct.fallename[2]= r.fallename;
        },
        // se ejecuta al extraer el APD
        extraeApd(r) {
            this.ct.apd_cod[2]= r.codigo;
        },
        // se ejecuta al pulsar el botón de borrar APD1
        clearApd() {
            //this.masterCt.zona_apd1[2]= 0;
        },

    },
    



};
</script>

<!-- Finder de Extras Liquidaciones APDS -->

<template>
  <div class="apdsLiqExtrasF" v-if="loaded">
    
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:860px"> 

            <!-- <div v-html="info"></div> -->

            <!-- Filtro de Búsqueda -->         
            <div class="conflex" style="width:65%">                                
                <uiText               
                    style="width:70%;"
                    v-model="ct.txt[2]"
                    :label="ct.txt[1]"
                    @Enter="eventFiltro('buscar')"> 
                </uiText> 
                <v-btn 
                    v-bind="$cfe.btn.busca" 
                    style="margin-left:5px"
                    @click="eventFiltro('buscar')">
                    <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn>
            </div>
    
          <!-- Grid -->             
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            dense
            height="440px"
            @onEvent="execAccion">
         
              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btGridCfg"
                  @onEvent="execAccion">
                </baseBtraMto>
              </template>

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>              
              </template>            
          </baseGridMD>           
        </div>
      </v-sheet>  

      <!-- Mto -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>

    </div>    
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";   
  import apdsLiqExtrasM from "@/components/apdsLiqExtrasM";
  
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, apdsLiqExtrasM },
    props: {},

    data() {
      return { 
        stIni: {
          api: "apdsLiqExtrasF",
          name:"apdsLiqExtrasF",
          mView:'apdsLiqExtrasM',
          titulo:"Apds Liquidaciones Extras", 
          pView:[]          
        },

        txt:'',        
      };
    },

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
    
        this.headers = [{
          name:[],
          fields:[    
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },            
            { text: "Fecha", value: "fhhr", witdh: "10%", filtro: 'fechahr' },
            { text: "APD", value: "name_apd_id", witdh: "30%" },
            { text: "Expediente", value: "control", witdh: "10%" },
            { text: "Descripcion", value: "des", witdh: "20%" },
            { text: "Importe", value: "imp", witdh: "10%", filtro: 'num' },
            { text: "Liq.", value: "liq", witdh: "10%" },
          ]}
        ];
        
        this.headerGrid= this.headerSet();    

        // le indico que el finder tiene un autoload con la función 'buscar'
        // al cargar el componente hago un recordGet          
        //this.autoLoad= 'buscar';        
      },

/* 
      // gancho después de obtener los registros del GRID
      recordsGetAfter() {
        this.filtrar();
      },

      // entra al buscar por el nombre del grupo
      async filtrar() {          
        await this.stFiltrar(this.filtrarGrupo, {});
      },

      // filtrar por Grupo de funeraria
      filtrarGrupo(records) {          
        return records.filter(elem=> elem.name.toLowerCase().includes(this.txt.toLowerCase()));
      },
 */
    
    }
  };
</script>

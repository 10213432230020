var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"apdsLiqExtrasM"},[_c('dualTemplate',{attrs:{"dualTipo":_vm.sync? '':'modal',"modal":_vm.modal,"storeName":_vm.storeRaiz,"persistent":""},scopedSlots:_vm._u([{key:"controles",fn:function({}){return [_c('div',[_c('div',{staticClass:"cabecera"},[_c('baseHeader',{attrs:{"cfg":_vm.$cfe.headers.mto,"titulo":_vm.$store.state[_vm.storeName].titulo},on:{"onEvent":_vm.eventHeader}})],1),_c('v-sheet',{attrs:{"elevation":4}},[_c('div',{staticClass:"contenedor",staticStyle:{"width":"600px"}},[(!_vm.readOnly)?_c('div',{staticClass:"pt-2 d-flex justify-left"},[_c('baseBtraMto',{attrs:{"perm":_vm.permMto,"modulo":_vm.btMtoCfg,"estado":_vm.estado},on:{"onEvent":_vm.execAccion}}),_c('baseBtraExtra',{staticStyle:{"margin-left":"25px"},attrs:{"permExtra":_vm.permExtra,"modulo":_vm.btExtCfg},on:{"onEvent":_vm.execAccion}})],1):_vm._e(),_c('div',{staticClass:"columna",staticStyle:{"padding-top":"20px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('uiDate',{staticStyle:{"width":"30%"},attrs:{"styleLabel":"width:100px","styleInput":"font-size: 14px;","type":"datetime-local","label":_vm.ct.fhhr[1],"readonly":""},model:{value:(_vm.ct.fhhr[2]),callback:function ($$v) {_vm.$set(_vm.ct.fhhr, 2, $$v)},expression:"ct.fhhr[2]"}}),_c('uiText',{staticStyle:{"width":"30%"},attrs:{"styleLabel":"width:100px","label":_vm.ct.liq[1],"readonly":""},model:{value:(_vm.ct.liq[2]),callback:function ($$v) {_vm.$set(_vm.ct.liq, 2, $$v)},expression:"ct.liq[2]"}}),_c('uiText',{staticStyle:{"width":"30%"},attrs:{"styleLabel":"width:100px","label":_vm.ct.liq_fh[1],"readonly":""},model:{value:(_vm.ct.liq_fh[2]),callback:function ($$v) {_vm.$set(_vm.ct.liq_fh, 2, $$v)},expression:"ct.liq_fh[2]"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('ctrlF',{staticStyle:{"width":"80%"},attrs:{"ct":_vm.ct.apd_id,"fn":_vm.extraeApd,"tipoView":"FCA","mView":"apdM","ctView":{                            
                              codigo:['codigo', 'Código'],
                              name:['name', 'Nombre'],
                              emp:['emp', 'Empresa'],                            
                              tlf1:['tlf1', 'Teléfono'],
                              mov1:['mov1', 'Móvil'],
                              fax1:['fax1', 'Fax'],
                              email1:['email1', 'Email'],                            
                          },"masterStore":_vm.masterStore,"edicion":!_vm.noEdit},on:{"clear":_vm.clearApd}}),_c('uiText',{staticStyle:{"width":"20%"},attrs:{"styleLabel":"width:100px","label":_vm.ct.apd_cod[1],"readonly":""},model:{value:(_vm.ct.apd_cod[2]),callback:function ($$v) {_vm.$set(_vm.ct.apd_cod, 2, $$v)},expression:"ct.apd_cod[2]"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('ctrlF',{staticStyle:{"width":"30%"},attrs:{"ct":_vm.ct.exp_id,"fn":_vm.extraeExp,"tipoView":"FCA","mView":"expM","ctView":{                            
                              control:['control', 'Expediente'],
                              fallename:['fallename', 'Fallecido'],
                                                         
                          },"masterStore":_vm.masterStore,"edicion":!_vm.noEdit},on:{"clear":_vm.clearApd}}),_c('uiText',{staticStyle:{"width":"70%"},attrs:{"styleLabel":"width:100px","label":_vm.ct.fallename[1],"readonly":""},model:{value:(_vm.ct.fallename[2]),callback:function ($$v) {_vm.$set(_vm.ct.fallename, 2, $$v)},expression:"ct.fallename[2]"}})],1),_c('div',{staticStyle:{"display":"flex","margin-top":"15px","margin-bottom":"20px"}},[_c('uiText',{staticStyle:{"font-weight":"semibold","width":"75%"},attrs:{"label":_vm.ct.des[1],"disabled":_vm.noEdit},model:{value:( _vm.ct.des[2]),callback:function ($$v) {_vm.$set(_vm.ct.des, 2, $$v)},expression:" ct.des[2]"}}),_c('uiText',{staticStyle:{"font-weight":"bold","width":"25%"},attrs:{"label":_vm.ct.imp[1],"format":"money","ndecimals":2,"disabled":_vm.noEdit},model:{value:( _vm.ct.imp[2]),callback:function ($$v) {_vm.$set(_vm.ct.imp, 2, $$v)},expression:" ct.imp[2]"}})],1)])])]),_c(_vm.componenteDinamico,{tag:"component",attrs:{"syncUpdate":"","storeRaiz":_vm.storeName,"masterStore":_vm.storeName,"readOnly":_vm.readOnly,"viewMtoOnly":_vm.viewMtoOnly,"tipoDocumento":"6"},on:{"onEvent":function($event){$event.accion==6 || $event.accion=='salir'? _vm.componenteDinamico=null : ''}}})],1)]}}],null,false,3683389848)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }